import { getDate } from '../header/utils'

// Keep in sync with the original in charts repo in app/constants/common.ts
export const PREMARKET_AFTERMARKET_HOURS = 2.5
export const MARKET_START = { hours: 9, minutes: 30 }
export const MARKET_END = { hours: 16, minutes: 0 }

/*
 * Keep in sync with the original (Utils static method) in charts repo in app/utils.ts
 * See line 15, we use getDate instead of convertLocalToNyTime
 */
export function isStockFastRefreshAvailable(hasPremarket = true, hasAftermarket = true, bufferMinutes = 30) {
  const getMinutes = ({ hours, minutes }: { hours: number; minutes: number }) => hours * 60 + minutes

  const date = getDate()
  const dayOfWeek = date.getDay()
  const currentDayMinutes = date.getHours() * 60 + date.getMinutes()

  const premarketMinutes = hasPremarket ? PREMARKET_AFTERMARKET_HOURS * 60 : 0
  const aftermarketMinutes = hasAftermarket ? PREMARKET_AFTERMARKET_HOURS * 60 : 0

  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6
  const isOpen = getMinutes(MARKET_START) - premarketMinutes - bufferMinutes <= currentDayMinutes
  const isClosed = getMinutes(MARKET_END) + aftermarketMinutes + bufferMinutes <= currentDayMinutes
  return !isWeekend && isOpen && !isClosed
}
