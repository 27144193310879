import * as React from 'react'
import { useInRouterContext } from 'react-router-dom'

import { isMobile } from '../../../app/shared/isMobile'
import { DesktopSelect, DesktopSelectProps } from './DesktopSelect'
import { NativeSelect, NativeSelectInRouterContext } from './NativeSelect'
import { SelectButton } from './SelectButton'
import { SelectHandle } from './types'
import { SelectItem } from './types'
import { findSelectedIndex, findSelectedValue } from './utils'

const isMobileDevice = isMobile()

export interface SelectProps<ValueType, ItemType, Name> extends DesktopSelectProps<ValueType, ItemType, Name> {
  /**
   * Whether or not to show a native select when clicking the button.
   * When rendering native select, you must provide `items`, `value` and `onChange`
   *
   * @default false
   */
  nativeSelect?: boolean | 'mobile'
}

function SelectComponent<ValueType, ItemType = ValueType, Name = never>(
  {
    name,
    label,
    value: providedValue,
    defaultValue: providedDefaultValue,
    onChange,
    items,
    trigger,
    gutter,
    orientation,
    triggerContent,
    isInteractive = true,
    onInteractionChange,
    nativeSelect = false,
    comboBox = 10,
    onSearch,
    'data-testid': dataTestid,
    listProps,
    virtualized,
    ...props
  }: React.PropsWithChildren<SelectProps<ValueType, ItemType, Name>>,
  ref: React.ForwardedRef<SelectHandle>
) {
  // Used by native select to handle navigation for link items
  const isInRouterContext = useInRouterContext()
  // Let users either provide the selected item or its value. Items can also be grouped so we need to find the correct value
  const value = findSelectedValue(items, providedValue) ?? providedValue
  const defaultValue = findSelectedValue(items, providedDefaultValue) ?? providedDefaultValue

  // Extracted trigger to a variable because it is used in every branch
  const selectTrigger = trigger ?? (
    <SelectButton
      {...props}
      // Bit of a workaround to provide the correct text to render for native select or when the select isn’t interactive to save resources
      valueOverride={
        !isInteractive || (nativeSelect !== false && items?.length) ? (value as SelectItem<ValueType>) : undefined
      }
      data-testid={dataTestid}
    >
      {triggerContent}
    </SelectButton>
  )

  // Save some resources by rendering the trigger only
  if (!isInteractive) {
    return selectTrigger
  }

  const renderNativeSelect = nativeSelect === true || (nativeSelect === 'mobile' && isMobileDevice)
  if (renderNativeSelect) {
    const NativeSelectComponent = isInRouterContext ? NativeSelectInRouterContext : NativeSelect
    const selectedIndex = findSelectedIndex(items, providedValue ?? providedDefaultValue)

    return (
      <div className="relative w-full">
        {selectTrigger}
        <NativeSelectComponent
          /**
           * The key attribute fixes error on iOS Safari, where the placeholder option wouldn’t get
           * hidden in some cases
           */
          key={selectedIndex}
          items={(items ?? []) as SelectItem<ValueType>[]}
          onChange={(item) => onChange?.(item as any, name!)}
          value={selectedIndex}
          placeholder={props.placeholder}
        />
      </div>
    )
  }

  return (
    <DesktopSelect
      {...props}
      ref={ref}
      name={name}
      label={label}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange as any}
      items={items as any}
      trigger={selectTrigger}
      comboBox={comboBox}
      onSearch={onSearch}
      onInteractionChange={onInteractionChange}
      data-testid={dataTestid}
      gutter={gutter}
      orientation={orientation}
      listProps={listProps}
      virtualized={virtualized}
    />
  )
}

export const Select = React.forwardRef(SelectComponent) as <ValueType, ItemType = ValueType, Name = never>(
  props: React.PropsWithChildren<SelectProps<ValueType, ItemType, Name>> & {
    ref?: React.ForwardedRef<SelectHandle>
  }
) => ReturnType<typeof SelectComponent>
