import { TableAlign } from '../../components/Table'
import { SortableColumn } from '../../hooks/use-sort-data'
import { NullableNumbers } from '../../types'

export enum PortfolioQuery {
  View = 'v',
  TableView = 'tv',
  PortfolioId = 'pid',
  Order = 'o',
  Tickers = 't',
  Map = 'map',
  Size = 'mapSize',
  Subtype = 'subtype',
  Group = 'group',
}

export enum PortfolioMapSizeKey {
  MarketValue = 'market',
  Gain = 'gain',
}

export enum PortfolioView {
  View = '1',
  Edit = '2',
}

export enum PortfolioTableView {
  Ticker = '1',
  Trade = '2',
}

export const PortfolioTableViewLabels = {
  [PortfolioTableView.Ticker]: 'Tickers',
  [PortfolioTableView.Trade]: 'Trades',
}

export enum PortfolioSymbolType {
  Stock = 'stock',
  Cash = 'cash',
}

export enum PortfolioTransaction {
  Watch = 0,

  // Stock transactions
  Buy = 1,
  Short = 2,

  // Cash transactions
  CashDeposit = 200,
  CashWithdraw = 201,
  CashIncome = 202,
  CashFee = 203,
}

export enum NumberOfShares {
  None = 0,
  /**
   * The usage of this is technically incorrect. The value for `PositiveNumber` is 1.
   * But we don’t care in this context, because we only want to differentiate betwen zero/non-zero values,
   * which helps us get correct types.
   *
   * # Don’t compare `entry.shares === NumberOfShares.PositiveNumber`
   */
  PositiveNumber = 1,
}

export interface PortfolioEntryBase {
  id: number
  ticker: string
  date: string
  transaction: PortfolioTransaction
  shares: number
  price: number
  type: PortfolioSymbolType
}

/**
 * Base type which lists all common properties
 */
export interface PortfolioEntryComputed {
  valuePaid: number
  valueMarket: number
  valueMarketPrev: number
  gainMarket: number
  gainMarketPct: number
  gainMarketUsd: number
  gainTodayUsd: number
  lastChangePct: number
}

/**
 * Transaction with > 0 shares
 */
export interface PortfolioTransactionStake extends PortfolioEntryBase, PortfolioEntryComputed {
  shares: NumberOfShares.PositiveNumber
}

/**
 * Transaction with 0 shares
 */
export interface PortfolioTransactionWatch
  extends Omit<PortfolioEntryBase, 'shares'>,
    NullableNumbers<PortfolioEntryComputed> {
  shares: NumberOfShares.None
  transaction: PortfolioTransaction
}

export type PortfolioEntryTransaction = PortfolioTransactionStake | PortfolioTransactionWatch

export type PortfolioActionType = PortfolioEntryTransaction & { isNotification?: boolean; message?: string }

/**
 * Top level portfolio entry
 */
export interface PortfolioGroup extends PortfolioEntryComputed {
  ticker: string
  shares: number
  industry: string
  country: string
  company: string
  marketCap: number | null
  aum: number | null
  prevClose: number
  lastClose: number
  lastVolume: number
  perfWeek: number
  costAvg: number
  transactions: PortfolioEntryTransaction[]
  order: number
  id: number
  date: string
  type: PortfolioSymbolType
  isDelisted?: true
}

export type PortfolioTrade = Omit<PortfolioGroup, 'transactions'> & PortfolioEntryTransaction

export type PortfolioMapEntry = Omit<PortfolioGroup, 'transactions'> & PortfolioTransactionStake

/**
 * Summary row for the whole portfolio
 */
export interface PortfolioSummary {
  tickers: number
  transactions: number
  shares: number
  lastChangePct: number
  valuePaid: number
  valueMarket: number
  valueMarketStocks: number
  valueMarketStocksPrev: number
  gainMarketUsd: number
  gainMarketPct: number
  gainTodayUsd: number
}

export interface PortfolioDefinition {
  id: number
  name: string
  order: number
}

export interface PortfolioInitData {
  limit: number
  limitTransactions: number
  selectedPortfolio: number
  portfolios: PortfolioDefinition[]
  portfolio: PortfolioGroup[]
  news: string[]
  version?: number
}

export interface PortfolioFormEntry
  extends Pick<PortfolioEntryBase, 'ticker'>,
    Partial<Omit<PortfolioEntryBase, 'ticker'>> {
  uuid: string
}

export interface PortfolioFormGroup extends Pick<PortfolioGroup, 'ticker'> {
  id: string
  transactions: PortfolioFormEntry[]
}

export enum ColumnId {
  Spacer = 'spacer',
  Ticker = 'ticker',
  Company = 'company',
  LastClose = 'lastClose',
  CostAvg = 'costAvg',
  LastChange = 'lastChange',
  LastChangePct = 'lastChangePct',
  LastVolume = 'lastVolume',
  Transaction = 'transaction',
  Date = 'date',
  Shares = 'shares',
  Price = 'price',
  ValuePaid = 'valuePaid',
  Amount = 'amount',
  ValueMarket = 'valueMarket',
  GainMarketUsd = 'gainMarketUsd',
  GainMarketPct = 'gainMarketPct',
  GainMarketCombined = 'gainMarketCombined',
  GainTodayUsd = 'gainTodayUsd',
  GainTodayCombined = 'gainTodayCombined',
  Order = 'order',
}

export enum RowKind {
  Group, // A group of transactions
  Transaction, // A transaction made inside ticker
  Summary, // Portfolio summary
  Watchlist, // Charts portfolio watchlist table
  Trade,
}

interface GroupProps {
  kind: RowKind.Group
  row: PortfolioGroup
}

interface TradeProps {
  kind: RowKind.Trade
  row: PortfolioTrade
}

interface TransactionProps {
  kind: RowKind.Transaction
  row: PortfolioEntryTransaction
}

interface SummaryProps {
  kind: RowKind.Summary
  row: PortfolioSummary
}

interface WatchlistProps {
  index: number
  kind: RowKind.Watchlist
  row: PortfolioGroup
}

export interface TableColumnDefinition<
  PropsType = (GroupProps | TradeProps | TransactionProps | SummaryProps | WatchlistProps) & { rowIndex?: number },
> extends SortableColumn<ColumnId, GroupProps | TradeProps | TransactionProps> {
  urlSort: string
  name: string
  align?: TableAlign

  /**
   * Get the value of the column and render it
   */
  renderColumn: (props: PropsType) => React.ReactNode
}

export enum ActionKind {
  AddTickers,
  RemoveTicker,
  AddTransaction,
  EditTransaction,
  RemoveTransaction,
  ReorderTickers,
  PortfoliosReorder,
}

export interface Action<PayloadType> {
  kind: ActionKind
  payload: PayloadType
}

export type AddTickersError = {
  tickers?: string
}

export interface CreatePortfolioErrors extends AddTickersError {
  portfolioName?: string
}

export interface CreatePortfolioData {
  tickers: string
  portfolioName: string
}

export type FormErrors = Partial<Record<keyof PortfolioEntryBase, boolean>>

export interface PortfolioBulkEditErrors {
  portfolioName: boolean
  rows: Record<string, FormErrors[]>
}

export type NotificationPayload = { isNotification?: boolean; message?: string }
export type ActionPayload = CreatePortfolioErrors | NotificationPayload
